import * as React from 'react';
import WorksListItem from './WorksListItem';

type Props = {
  items: any
}

const WorksList: React.FunctionComponent<Props> = ({ items }) => (
  <div className="boxes">
    {items.allWordpressWpWorks.edges.map(item => (
      <div className="box" key={item.node.id}>
        <WorksListItem item={item} />
      </div>
    ))}
  </div>
)

export default WorksList
