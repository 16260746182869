import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Works } from '../../interface'
import Image from '../../components/image'





class WorksListItem extends React.Component<{item?:Works}> {

	works:any = {};


  constructor(props:any) {
    super(props);
    
    if(this.props.item)
    this.works = this.props.item;
	
  }

// const WorksListDetail: React.FunctionComponent<WorksListDetailProps> = ({
//   item: works,
// }) => (

	render(){

    let subtitle = this.works.node.acf.subtitle ? '<br />' + this.works.node.acf.subtitle : ''
    let url = '/works/' + this.works.node.slug + '/'
    let featuredImage = this.works.node.featured_image_src.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/');
		return (  
      <Link to={url}>
        <img src={featuredImage} alt=""/>
        <p className="post-title">
          {this.works.node.title}{ReactHtmlParser(subtitle)}
        </p>
      </Link>
    )
  }

}


export default WorksListItem

