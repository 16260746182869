import * as React from 'react'
//import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../components/layout'
import WorksList from '../components/works/WorksList'
import { Works } from '../interface'
import Helmet from "react-helmet"
import SEO from "../components/seo"

type Props = {
  items: Works[]
  pathname: string,
  data: any
}


class IndexPage extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(){
    document.body.className = '';
    document.body.classList.add('home-page');
  }

  render() {
    
    return <Layout>
    <SEO title="YOJIRO IMASAKA" />
    <Helmet title="YOJIRO IMASAKA" />
    <section className="works-section">
      <div className="container">
        <div className="page-contents">
          <WorksList items={this.props.data} />
        </div>
      </div>
    </section>
  </Layout>
  
  }
}

export default IndexPage



export const query = graphql`
      query {
        __typename
        allWordpressWpWorks {
          edges {
            node {
              id
              title
              slug
              featured_image_src
              acf {
                subtitle
                works_images {
                  works_images_image {
                    url
                  }
                  works_images_image_caption
                  works_images_image_number_of_columns
                }
              }
            }
          } 
        }       
      }

    `;
